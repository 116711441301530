import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"
import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import StartTrialBtn from "../../components/startTrialBtn"

import * as styles from "./styles.module.css"

export default function Apty() {
  const features = [
    {
      category: "Pricing",
      nickelled: "From $249/mo",
      competitor: "Not transparent.<br/>Believed to be $299/mo and up.",
    },
    {
      category: "Unlimited monthly active users",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Not included</span>❌",
    },
    {
      category: "Instant free trial",
      tooltip: "Try the product without speaking to the sales team.",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Not included</span>❌",
    },
    {
      category: "Unlimited flows on trial",
      tooltip: "Fully-functional free trial.",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Not included</span>❌",
    },
    {
      category: "Unlimited users",
      nickelled: "<span class='sr-only' >Included</span>✅",
      competitor: "<span class='sr-only'>Not included</span>❌",
    },
    {
      category: "Developer integration optional",
      tooltip: "Use the product without involving any developers.",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Not included</span>❌",
    },
    {
      category: "Chrome extension available",
      tooltip: "Use a Chrome extension if you'd prefer.",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only' >Included</span>✅",
    },
    {
      category: "Analytics Included",
      tooltip: "Access analytics on usage.",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "❌<br/>Only some plans.",
    },
    {
      category: "Compatible with third-party software",
      tooltip:
        "Works with software such as Salesforce, Workday and Confluence.",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Included</span>✅",
    },
    {
      category: "Multiple language support",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Included</span>✅",
    },
    {
      category: "User identification and targeting",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Included</span>✅",
    },
    {
      category: "Custom branding",
      tooltip: "Blend your guidance to your site.",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Included</span>✅",
    },
    {
      category: "Custom domain support",
      tooltip: "Use your guidance on any domain.",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Included</span>✅",
    },
    {
      category: "Weekly reports",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Not included</span>❌",
    },
    {
      category: "Support and implementation",
      nickelled: "<span class='sr-only'>Included</span>✅",
      competitor: "<span class='sr-only'>Not included</span>❌",
    },
  ]
  return (
    <>
      <SEO
        title="The BEST Apty Alternative in 2021"
        description="An easy-to-use Apty alternative you'll love. Nickelled is the top Apty competitor."
      />
      <LiveChatLoaderProvider providerKey="ii6t4rrk" provider="intercom">
        <Intercom color="#FF9C43" />
      </LiveChatLoaderProvider>
      <Helmet>
        <script type="application/ld+json">
          {`
            {"@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Alternatives",
              "item": "https://www.nickelled.com/nickelled-alternatives/"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "Apty Alternative",
              "item": "https://www.nickelled.com/alternatives/apty/"
            }]}
          `}
        </script>
      </Helmet>
      <Header />

      <div class="w-full">
        <div class="max-w-5xl mx-auto py-16 text-center">
          <ol className="pb-8 text-base">
            <li className="inline-block">
              <Link to="/nickelled-alternatives/" class="text-oOrange">
                Alternatives
              </Link>{" "}
              <span className="mx-2">{">>"}</span>
            </li>
            <li className="inline-block">Apty</li>
          </ol>
          <h1 class="pb-6 text-6xl font-black text-gray-800 text-center leading-tight">
            An Apty alternative that’s suited to all sizes of business
          </h1>
          <h3 class="mx-auto max-w-2xl text-gray-700 text-lg md:text-2xl text-center leading-normal md:leading-extra-loose">
            Nickelled offers an alternative to Apty which is easier to use and
            more reasonably priced. Keep reading for a clear Apty comparison.
          </h3>
          <h3 class="mx-auto max-w-2xl text-gray-700 text-lg md:text-2xl text-center leading-normal md:leading-extra-loose">
            If you’re already using Apty and looking to switch, or you’re taking
            your first step into the world of digital adoption software, you
            should consider Nickelled.
          </h3>
          <div class="mt-6">
            <StartTrialBtn
              label="Start free trial"
              destination="https://app.nickelled.com/account/signup"
            ></StartTrialBtn>
          </div>
        </div>
      </div>
      <div class="w-full">
        <div class="max-w-3xl mx-auto py-16 text-left text-xl">
          <StaticImage
            src="../../images/alternatives/apty-alternative.jpg"
            alt="Apty alternative hero image"
          />{" "}
          <p>
            <span class="font-bold">
              Nickelled is just like Apty, but it’s much easier to use and has
              been designed for small businesses, by a small business.
            </span>
          </p>
          <p>
            So you can expect great support from a friendly team, a flexible
            product, and pricing which works.
          </p>
          <p>
            Apty has a lot of clients, but they describe themselves as an
            “Enterprise Transformation Platform”.{" "}
            <span class="font-bold">That means a lot of features,</span> which
            you may not need but you’ll have to pay for.
          </p>
          <p>
            <span class="font-bold">Nickelled is far simpler.</span> We offer
            the basics, at a great price which is designed for businesses who
            don’t have hundreds of thousands to spend on enterprise software.
          </p>
          <p>
            So if you’re looking for a simple Apty alternative, available to you
            at a lower price, you’re in the right place. Keep reading to find
            out why Nickelled is the Apty competitor that you can trust.
          </p>
          <div class="mt-6">
            <StartTrialBtn
              label="Try Nickelled free now"
              destination="https://app.nickelled.com/account/signup"
            ></StartTrialBtn>
          </div>
        </div>
      </div>

      <div class="py-12 bg-gray-50 overflow-hidden py-12 relative">
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            role="img"
            aria-labelledby="svg-workcation"
          >
            <title id="svg-workcation"></title>
            <defs>
              <pattern
                id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
            />
          </svg>

          <div class="relative">
            <blockquote class="mt-10">
              <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                <p>
                  &ldquo;Nickelled was the only company that we found where we
                  could create tutorials to our liking without needing to add
                  HTML code to our website.&rdquo;
                </p>
              </div>
              <footer class="mt-8">
                <div class="md:flex md:items-center md:justify-center">
                  <div class="hidden md:flex md:flex-shrink-0">
                    <StaticImage
                      src="../images/index/lara-fernandez.png"
                      class="mx-auto h-10 w-10 rounded-full"
                      alt="Lara Fernandez, Community Manager Gengo"
                    />
                  </div>
                  <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div class="text-base font-medium text-gray-900">
                      Lara Fernandez
                    </div>
                    <svg
                      class="hidden md:block mx-1 h-5 w-5 text-oOrange"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div class="text-base font-medium text-gray-500">
                      Community Manager, Gengo
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>

      <div class="relative py-16 sm:py-24 lg:py-32">
        <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Five Reasons to Love Nickelled
          </h2>

          <div class="mt-12">
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
              <div class="pt-6">
                <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-oOrange rounded-md shadow-lg">
                        <svg
                          height="20px"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 785 1280"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-white"
                        >
                          <path
                            d="m4495 12298c-604-535-1486-866-2660-998-331-37-854-70-1104-70h-101l-2-415-3-416 30-29 30-29 735-4c620-3 753-7 850-21 149-22 254-50 316-86 82-46 123-142 161-372 16-95 18-371 21-3663 2-2593 0-3591-8-3675-44-446-177-714-416-838-279-144-663-202-1350-202h-330l-27-28-27-28v-389-389l27-28 27-28h3386 3386l27 28 27 28v390 390l-27 26-28 26-390 5c-415 5-557 17-779 62-212 43-367 103-480 187-156 115-260 347-312 693-17 114-18 350-21 5005l-3 4884-27 28-27 28-410-1h-411z"
                            fill="#FFFFFF"
                            transform="matrix(.1 0 0 -.1 0 1280)"
                          />
                        </svg>
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      So Easy, the Whole Team Can Use It
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      Nickelled is the easiest product to use on the market,
                      guaranteed. Most of our customers have their first Flow
                      completed in five minutes after signing up. And with
                      unlimited users included in every plan, anybody in your
                      team can author or edit a Nickelled website walkthrough.
                    </p>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8 h-full">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-oOrange rounded-md shadow-lg">
                        <svg
                          height="20px"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 785 1280"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-white"
                        >
                          <path
                            d="m3445 12403c-603-49-1043-156-1471-358-581-274-1041-698-1289-1188-146-289-219-588-232-942-19-539 124-953 437-1265 194-194 412-311 693-372 138-30 484-33 619-5 263 53 486 170 666 348 227 225 340 462 373 782 43 426-125 829-430 1031-36 24-198 103-360 176-162 72-306 139-320 148-41 25-81 65-97 94-45 87 31 236 184 357 259 207 614 321 994 321 850-1 1367-467 1517-1367 36-214 45-343 45-623-1-503-68-912-229-1395-164-492-361-863-716-1345-284-385-570-731-1055-1276-814-914-1076-1226-1345-1605-715-1005-1043-1906-1105-3032l-7-128 28-30 29-29h3417 3416l6 33c2 17 107 771 233 1674l228 1643-29 30-29 30h-388-388l-4-22c-3-13-15-84-27-158-42-264-115-504-196-650-118-211-334-300-798-330-86-6-907-10-1934-10h-1783l7 28c22 91 169 383 287 572 250 399 666 844 1149 1231 298 239 521 394 964 669 826 512 1176 750 1581 1074 567 452 884 819 1123 1296 245 491 354 1007 338 1610-6 239-21 379-63 579-174 844-721 1532-1559 1962-484 249-1031 392-1680 439-126 9-700 12-800 3z"
                            fill="#ffffff"
                            transform="matrix(.1 0 0 -.1 0 1280)"
                          />
                        </svg>
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      Zero Development or Downloads
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      There’s no difficult development work, so you can create
                      your first guide in five minutes or less. There are also
                      no software downloads or browser extensions.
                    </p>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8 h-full">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-oOrange rounded-md shadow-lg">
                        <svg
                          height="20px"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 785 1280"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-white"
                        >
                          <path
                            d="m3600 12374c-221-15-362-27-465-40-729-91-1321-315-1780-675-119-93-317-292-396-399-170-229-275-472-331-770-19-96-22-152-23-340 0-194 3-240 22-335 40-195 103-355 196-499 217-332 548-535 960-586 125-16 403-7 507 15 251 55 448 162 633 343 199 195 311 432 339 714 44 454-160 879-612 1273-123 107-150 141-142 180 13 72 170 179 342 235 213 69 530 77 790 20 124-26 201-54 325-115 401-198 708-627 855-1194 134-515 154-1238 49-1766-84-423-249-765-483-1001-158-160-310-248-515-301-130-33-263-39-514-22-117 8-289 14-382 14h-170l-67-33c-77-38-171-127-211-200-124-228-36-500 198-612 127-61 284-73 590-45 206 19 303 19 433-1 268-40 476-145 668-338 261-264 438-668 529-1210 36-217 53-395 67-693 24-529-26-1002-143-1369-218-681-644-1103-1237-1223-222-46-505-52-712-16-309 54-618 230-662 378l-12 39 50 47c28 25 105 92 172 149 299 253 484 542 553 862 29 137 32 389 6 523-80 404-343 718-737 880-192 79-378 111-645 111-215 0-305-12-472-60-345-99-672-375-839-707-135-268-181-623-127-982 85-573 421-1063 1005-1468 614-426 1386-656 2293-686 1451-47 2614 329 3380 1094 423 422 662 897 756 1505 20 125 23 185 23 440 1 312-6 399-50 650-220 1232-1121 2032-2739 2431-110 27-208 49-217 49-42 0-12 17 70 39 48 13 190 57 316 98 1174 382 1881 924 2201 1688 125 298 174 554 182 943 7 369-15 584-93 881-47 182-91 297-184 486-162 328-384 604-676 839-526 423-1180 662-2029 742-150 14-724 26-845 18z"
                            fill="#ffffff"
                            transform="matrix(.1 0 0 -.1 0 1280)"
                          />
                        </svg>
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      Clear Pricing
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      We have two plans - an off-the-shelf package suitable for most businesses, and a custom package. Get in touch to find out more!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-1 gap-8 lg:grid-cols-6 pt-8">
              <div class="pt-6 col-span-2 md:col-start-2">
                <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8 h-full">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-oOrange rounded-md shadow-lg">
                        <svg
                          height="20px"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 785 1280"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-white"
                        >
                          <path
                            d="m4854 12317c-169-281-3758-6273-4286-7153l-138-231v-615-614l28-27 28-27h1838 1839l-6-532c-5-556-14-694-56-885-58-268-149-413-309-497-206-107-618-193-1062-221-52-3-110-8-127-11l-33-5v-416-417l27-28 27-28h2746 2746l27 28 27 28v417 416l-32 5c-18 3-76 8-128 11-243 14-528 64-699 121-213 71-314 148-386 298-112 232-145 517-145 1274v442h667 667l28 27 28 27v526 527l-23 21-23 22-670 2-669 3-3 3804-2 3805-27 28-27 28h-890-891zm-331-2099c-10-65-174-2286-233-3173-48-730-130-2111-130-2212v-33h-1465c-806 0-1465 3-1465 6 0 11 3285 5434 3291 5434 3 0 5-10 2-22z"
                            fill="#ffffff"
                            transform="matrix(.1 0 0 -.1 0 1280)"
                          />
                        </svg>
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      Works on Every Software Package
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      We know that you can’t always use code in products like
                      Confluence, Salesforce or Workday. So we built Nickelled
                      to allow you to author guides which work on these products
                      anyway – with no extensions and no extra software
                      required.
                    </p>
                  </div>
                </div>
              </div>

              <div class="pt-6 col-span-2">
                <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8 h-full">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-oOrange rounded-md shadow-lg">
                        <svg
                          height="20px"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 785 1280"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-white"
                        >
                          <path
                            d="m6855 12524c-282-116-413-166-570-217-377-124-753-201-1175-242-416-41-1296-27-1885 31-549 53-1113 183-1654 381-95 34-177 63-182 63s-24-10-43-22l-33-22-7-250c-8-293-23-734-31-891-66-1333-177-2460-366-3695-34-226-140-849-168-995-15-76-17-103-8-111 14-13 725-444 732-444 4 0 74 49 158 108 592 422 963 616 1323 693 141 30 391 37 542 15 419-62 744-281 980-662 241-389 378-918 421-1624 11-181 6-788-9-975-60-787-216-1346-490-1757-288-434-693-648-1225-650-244 0-428 38-607 128-145 72-242 155-278 237-18 41-19 51-8 64 7 9 128 112 268 228 310 258 357 310 445 498 93 198 131 400 122 657-13 370-130 637-392 888-198 191-409 301-695 364-93 20-132 22-335 22-226 0-232-1-350-32-142-38-325-124-430-204-246-186-434-470-520-784-46-165-57-251-62-470-8-301 18-493 97-734 263-805 1078-1396 2180-1584 481-82 1081-96 1599-36 976 112 1743 450 2335 1029 290 285 482 543 656 886 206 403 332 845 396 1385 21 183 30 754 15 960-57 747-273 1382-651 1910-666 931-1694 1410-2955 1377-418-11-761-73-1164-212-350-121-664-279-975-492l-108-74 5 43c4 24 63 657 133 1408 69 751 127 1366 128 1367s38-12 82-29c321-125 618-154 1489-145 736 8 1098 37 1520 123 716 145 1255 461 1699 994 39 47 105 122 148 167 229 246 367 589 394 980l7 101-38 69c-50 89-127 170-190 199-79 37-138 32-270-22z"
                            fill="#ffffff"
                            transform="matrix(.1 0 0 -.1 0 1280)"
                          />
                        </svg>
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      World-class Support
                    </h3>
                    <p class="mt-5 text-base text-gray-500">
                      We’re a small business, and we know how important support
                      is when you’re just getting started. So we respond to most
                      queries within one hour.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-12 bg-gray-50 overflow-hidden py-12 relative">
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            role="img"
            aria-labelledby="svg-workcation"
          >
            <title id="svg-workcation"></title>
            <defs>
              <pattern
                id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
            />
          </svg>

          <div class="relative">
            <blockquote class="mt-10">
              <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                <p>
                  &ldquo;We have specific use cases where people are logging on
                  for the first time and they don’t know anything about us.
                  Nickelled Flows is really helpful and useful. We’ve been with
                  Nickelled for a couple years now - I love it. &rdquo;
                </p>
              </div>
              <footer class="mt-8">
                <div class="md:flex md:items-center md:justify-center">
                  <div class="hidden md:flex md:flex-shrink-0">
                    <StaticImage
                      src="../../images/alternatives/matt-olson.jpeg"
                      class="mx-auto h-10 w-10 rounded-full"
                      alt="Matt C. Olson, Spectrum Pensions"
                    />
                  </div>
                  <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div class="text-base font-medium text-gray-900">
                      Matt C. Olson, CPC
                    </div>
                    <svg
                      class="hidden md:block mx-1 h-5 w-5 text-oOrange"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div class="text-base font-medium text-gray-500">
                      Spectrum Insurance Group
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>

      <div class="w-full hidden md:block">
        <div class="max-w-7xl mx-auto py-16 text-center text-xl">
          <h2 class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight leading-snug sm:text-4xl">
            Apty vs Nickelled:
            <br /> What’s the right choice for your digital adoption platform?
          </h2>
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 py-16">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Feature</span>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-lg font-medium text-oOrange uppercase tracking-wider"
                      >
                        Nickelled
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-lg font-medium text-oOrange uppercase tracking-wider"
                      >
                        Apty
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {features.map(feature => (
                      <tr key={feature.email}>
                        <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">
                          <div className="flex justify-start items-center">
                            <span className="mr-4">{feature.category}</span>
                            {feature.tooltip != null && (
                              <span
                                className={
                                  "hidden md:block " + styles.tooltipToggle
                                }
                                aria-label={feature.tooltip}
                                tabindex="0"
                              >
                                <svg
                                  viewBox="0 0 27 27"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="#888888" fill-rule="evenodd">
                                    <path d="M13.5 27C20.956 27 27 20.956 27 13.5S20.956 0 13.5 0 0 6.044 0 13.5 6.044 27 13.5 27zm0-2C7.15 25 2 19.85 2 13.5S7.15 2 13.5 2 25 7.15 25 13.5 19.85 25 13.5 25z" />
                                    <path d="M12.05 7.64c0-.228.04-.423.12-.585.077-.163.185-.295.32-.397.138-.102.298-.177.48-.227.184-.048.383-.073.598-.073.203 0 .398.025.584.074.186.05.35.126.488.228.14.102.252.234.336.397.084.162.127.357.127.584 0 .22-.043.412-.127.574-.084.163-.196.297-.336.4-.14.106-.302.185-.488.237-.186.053-.38.08-.584.08-.215 0-.414-.027-.597-.08-.182-.05-.342-.13-.48-.235-.135-.104-.243-.238-.32-.4-.08-.163-.12-.355-.12-.576zm-1.02 11.517c.134 0 .275-.013.424-.04.148-.025.284-.08.41-.16.124-.082.23-.198.313-.35.085-.15.127-.354.127-.61v-5.423c0-.238-.042-.43-.127-.57-.084-.144-.19-.254-.318-.332-.13-.08-.267-.13-.415-.153-.148-.024-.286-.036-.414-.036h-.21v-.95h4.195v7.463c0 .256.043.46.127.61.084.152.19.268.314.35.125.08.263.135.414.16.15.027.29.04.418.04h.21v.95H10.82v-.95h.21z" />
                                  </g>
                                </svg>
                              </span>
                            )}
                          </div>
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500"
                          dangerouslySetInnerHTML={{
                            __html: feature.nickelled,
                          }}
                        />

                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500"
                          dangerouslySetInnerHTML={{
                            __html: feature.competitor,
                          }}
                        />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-12 bg-gray-50 overflow-hidden py-12 relative">
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            role="img"
            aria-labelledby="svg-workcation"
          >
            <title id="svg-workcation"></title>
            <defs>
              <pattern
                id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
            />
          </svg>

          <div class="relative">
            <blockquote class="mt-10">
              <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                <p>
                  &ldquo;We found Nickelled whilst looking for a solution to
                  onboard our users without the need for extensive dev work -
                  and we haven't looked back since! Perfect solution, great
                  product, and brilliant support. &rdquo;
                </p>
              </div>
              <footer class="mt-8">
                <div class="md:flex md:items-center md:justify-center">
                  <div class="hidden md:flex md:flex-shrink-0">
                    <StaticImage
                      src="../images/index/jessica-golding.jpg"
                      class="mx-auto h-10 w-10 rounded-full"
                      alt="Jessica Golding"
                    />
                  </div>
                  <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div class="text-base font-medium text-gray-900">
                      Jessica Golding
                    </div>
                    <svg
                      class="hidden md:block mx-1 h-5 w-5 text-oOrange"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div class="text-base font-medium text-gray-500">
                      Director of Customer Experience, 90Seconds{" "}
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>

      <div class="w-full">
        <div class="max-w-3xl mx-auto py-16 text-left text-xl">
          <h2 class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            So your hunt for the best Apty alternative is over!
          </h2>
          <p>
            <span class="font-bold">
              Want to see why people are switching from Apty to Nickelled?
            </span>
          </p>
          <p>
            Nickelled is so much simpler to use and easier to implement. You
            don’t even need to insert any code, if you don’t want to. Whereas
            Apty might require a team of developers, anyone in any team is able
            to use Nickelled to show users how to use software.
          </p>
          <p>
            With Nickelled, you’re dealing with a company which values your
            business and will work every day to retain it. We focus on SMEs
            rather than enterprise businesses, and we know what it’s like
            running a software business.
          </p>
          <p>
            If you’re looking for a change from Apty, or you just want a simple
            digital adoption platform that works… It’s time to try Nickelled.
            You can get started for free by clicking below.
          </p>
          <div class="mt-6">
            <StartTrialBtn
              label="Get started free now"
              destination="https://app.nickelled.com/account/signup"
            ></StartTrialBtn>
          </div>
        </div>
      </div>

      <div class="w-full">
        <div class="max-w-7xl mx-auto py-16  px-1 md:px-0">
          <div class="max-w-3xl bg-oOrange-light border border-oOrange mx-auto p-8">
            <h5 class="mt-0">Things to note</h5>
            <p class="mb-0">
              Nickelled has made every attempt to ensure the accuracy and
              reliability of the information provided on this website. However,
              the information is provided &quot;as is&quot; without warranty of
              any kind. Nickelled does not accept any responsibility or
              liability for the accuracy, content, completeness, legality, or
              reliability of the information contained on this website. This
              page is not associated with Apty.
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}
